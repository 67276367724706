
const ReportTexts = {
    AttemptedTo: "What did the user try to do?",
    AttemptOutcome: "What instead happened for the user?",
    TimesOccurred: "How many times has this occurred?",
    Type: "What does this issue relate to?",
    WhatEmail: "What email does the user have?",
    WhatGate: "Which gate did it occur on?",
    Platform: "What platform was used?",
    AppVersion: "What app version was used?"
}

export {ReportTexts}