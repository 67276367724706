
/**
 * Checks if a string is a valid email address.
 * @param {string} email The string to validate as an email.
 * @returns {boolean} Returns true if the string is a valid email, otherwise false.
 */
export function isValidEmail(email) {
    // This regular expression covers most valid email formats
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}