<template>
    <div class="number-picker">
        <div class="number-picker-segment">
          <button class="number-picker-button" :class="{disabled: (min ? min == value : false)}" @click="$emit('update:value', value - 1)">-</button>
        </div>
        <div class="number-picker-segment">
            <div class="center-labels">
                <label class="picker-value">{{ value }}</label>
                <label class="picker-suffix">{{ suffix }}</label>
            </div>
        </div>
        <div class="number-picker-segment">
         <button  class="number-picker-button" @click="$emit('update:value', value + 1)">+</button>
        </div>
    </div>
</template>

<script>

export default {
    emits: ["update:value"],
    props: {
        value: {
            type: Number,
            required: true
        },
        suffix: {
            type: String
        },
        min: {
            type: Number
        }
    }
}

</script>

<style> 

.number-picker {
    display: grid;
    grid-template-columns: 1fr 0.7fr 1fr;
}

.number-picker-segment {
    display: flex;
    justify-content: center;
    align-items: center;
}

.center-labels {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    font-size: 19px;
}

.picker-suffix {
    font-style: italic;
}

.picker-value, .picker-suffix {
    margin: 0;
}

.number-picker-button {
    --diameter: 33px;
    width: var(--diameter);
    height: var(--diameter);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: 0;
    padding: none;
    margin: none;
    text-align: center;
    background-color: var(--dark-blue);
    font-size: 18px;
    border-radius: 100em;
    line-height: var(--diameter);
    color: white;
    cursor: pointer;
}

.number-picker-button:active {
    opacity: 0.7;
}

.number-picker-button.disabled {
    background-color: lightgray;
    pointer-events: none;
}

</style>