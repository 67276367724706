<template>
    <div class="option-picker">
        <button v-for="(option, index) in options" :key="index" class="option-button"
            @click="clickOption(index)" :class="{selected: index == selected}">
            {{ option }}
        </button>
    </div>
</template>

<script>

export default {
    emits: ["update:selected", "deselect"],
    props: {
        selected: {
            type: Number,
            required: true
        },
        options: {
            type: Array,
            required: true
        }
    },
    methods: {
        clickOption(index) {
            if (index == this.selected) {
                this.$emit("deselect");
            } else {
                this.$emit("update:selected", index);
            }
        }
    }
}

</script>

<style scoped>
.option-picker {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 90%;
}

.option-button {
    cursor: pointer;
    padding: 10px 14px;
    margin: 5px;
    border: 1px solid #ccc; 
    border-radius: 100em;
    background-color: transparent; 
    transition: background-color 0.4s, color 0.4s;
}

.option-button:nth-child(4n+1) {
    clear: left;
}

/* Optionally, highlight the selected option */
.option-button.selected {
    background-color: var(--dark-blue);
    color: white;
    border-color: transparent;
}
</style>