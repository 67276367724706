<template>
    <div class="report-header">
        <div class="connector"></div>
        <button class="report-stage-button" v-for="thisStage in allStages" :class="{ selected: stage == thisStage }"
            @click="$emit(`update:stage`, thisStage)">
            {{ stage == thisStage ? titleForStage(thisStage) : thisStage }}
        </button>
    </div>
</template>

<script>
import { CreateReportStage } from '../middleware/CreateReportStage';

export default {
    emits: ["update:stage"],
    props: {
        stage: {
            type: Number,
            required: true
        }
    },
    data: () => ({
        allStages: [
            CreateReportStage.Description,
            CreateReportStage.Time,
            CreateReportStage.Details,
            CreateReportStage.App,
            CreateReportStage.Gate,
            CreateReportStage.Overview
        ]
    }),
    methods: {
        titleForStage(stage) {
            switch (stage) {
                case CreateReportStage.Description:
                    return "Info" 
                case CreateReportStage.Time:
                    return "Time"
                case CreateReportStage.Details:
                    return "Details"
                case CreateReportStage.App:
                    return "App"
                case CreateReportStage.Gate:
                    return "Gate";
                case CreateReportStage.Overview:
                    return "Overview"
            }
        }
    }
}

</script>

<style>
.report-header {
    display: flex;
    flex-direction: row;
    column-gap: 24px;
    position: relative;
    align-items: center;
}

.report-stage-button {
    border: none;
    padding: none;
    margin: none;
    background-color: var(--create-report-light-gray);
    color: var(--dark-blue); 
    border-radius: 100em;
    font-size: 13px; 
    z-index: 1;
    font-weight: 600;
    padding: 10px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    cursor: pointer;
}

.report-stage-button.selected {
    background-color: #e2e5e6;
    color: var(--dark-blue); 
    outline: none;
}

.connector {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #D7DDDF;
}
</style>