<template>
    <div>
        <CreateReportBox :title="ReportTexts.AttemptedTo">
            <textarea v-model="report.AttemptedToDescription" class="report-text-area" placeholder="The user tried to..." @input="$emit(`changed`)">
             </textarea>
        </CreateReportBox>
        <CreateReportBox :title="ReportTexts.AttemptOutcome">
            <textarea v-model="report.AttemptOutcomeDescription" class="report-text-area" placeholder="The action instead resulted in..." @input="$emit(`changed`)">
            </textarea>
        </CreateReportBox>
    </div>
</template>

<script>
import CreateReportBox from '../CreateReportBox.vue';
import Report from "../../middleware/Report";
import { ReportTexts } from "../ReportTexts";

export default {
    emits: ["changed"],
    components: { CreateReportBox },
    props: {
        report: {
            type: Object, 
            required: true
        }
    },
    data() {
        return {
            ReportTexts 
        };
    }
}
</script>
