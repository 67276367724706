export default class Report {
    /**
     * @param {number} [id] - The ID of the issue report.
     * @param {number} [gate] - The gate number associated with the error.
     * @param {Date} [occurredDate] - The datetime when the error occurred.
     * @param {String} [occurredTime] - The datetime when the error occurred.
     * @param {IssueType} [type] - The type of the issue.
     * @param {number} [attemptedBy] - The ID of the user who attempted the action.
     * @param {string} [attemptedToDescription] - Description of what was attempted.
     * @param {string} [attemptOutcomeDescription] - Description of the outcome of the attempt.
     * @param {string} [appVersion] - The version of the application.
     * @param {MobileDevicePlatform} [platform] - The mobile platform.
     * @param {boolean} [timesOccurred] - Flag indicating if the issue has occurred before.
     */
    constructor({
        id,
        gate,
        occurredDate,
        occurredTime,
        type,
        attemptedBy,
        attemptedToDescription,
        attemptOutcomeDescription,
        appVersion,
        platform,
        timesOccurred,
    } = { timesOccurred: 1 }) {
        this.Id = id;
        this.Gate = gate;
        this.OccurredDate = occurredDate;
        this.OccurredTime = occurredTime ?? ""
        this.Type = type;
        this.AttemptedBy = attemptedBy;
        this.AttemptedToDescription = attemptedToDescription;
        this.AttemptOutcomeDescription = attemptOutcomeDescription;
        this.AppVersion = appVersion;
        this.Platform = platform;
        this.TimesOccurred = timesOccurred;
    }

    get Occurred() {
        if (this.OccurredDate == null) {
            return undefined;
        }
        let d = new Date(this.OccurredDate);
        if (this.OccurredTime) {
            const [hours, minutes, seconds] = this.OccurredTime.split(':').map(part => parseInt(part, 10));
            d = this.createIsoStringWithUtcTime(hours, minutes, d)
        }
        return d
    }

    createIsoStringWithUtcTime(hour, minute, date) {
        // Clone the date to avoid modifying the original date
        let adjustedDate = new Date(date.getTime());

        // Set the time to midnight to start with a clean slate
        adjustedDate.setHours(0, 0, 0, 0);

        // Calculate the local timezone offset in milliseconds
        const timezoneOffset = adjustedDate.getTimezoneOffset() * 60000;

        // Create a UTC date at midnight
        const utcMidnight = new Date(adjustedDate.getTime() + timezoneOffset);

        // Set the UTC hour and minute
        utcMidnight.setUTCHours(hour, minute);

        // Return the ISO string
        return utcMidnight
    }


    updateTime(existingDate, timeString) {
        const [hours, minutes, seconds] = timeString.split(':').map(part => parseInt(part, 10));

        existingDate.setUTCHours(0, 0, 0, 0)
        // Set hours, minutes, and seconds to the existing date in UTC
        existingDate.setUTCHours(hours);
        existingDate.setUTCMinutes(minutes);
        existingDate.setUTCSeconds(seconds);

        return existingDate;
    }

    /**
   * Checks if the current report instance has changes compared to another report instance.
   * @param {Report} rh - Another report instance to compare with.
   * @returns {boolean} - True if there are changes, false otherwise.
   */
    hasChanges(rh) {
        // Check if 'rh' is a Report instance and has the necessary properties
        if (!(rh instanceof Report)) {
            throw new Error('The argument must be an instance of Report');
        }

        // Compare each property
        return this.Id !== rh.Id ||
            this.Gate !== rh.Gate ||
            this.OccurredDate !== rh.OccurredDate ||
            this.OccurredTime !== rh.OccurredTime ||
            this.Type !== rh.Type ||
            this.AttemptedBy !== rh.AttemptedBy ||
            this.AttemptedToDescription !== rh.AttemptedToDescription ||
            this.AttemptOutcomeDescription !== rh.AttemptOutcomeDescription ||
            this.AppVersion !== rh.AppVersion ||
            this.Platform !== rh.Platform ||
            this.TimesOccurred !== rh.TimesOccurred;
    }
    

    duplicate() {
        const cloneInstance = new Report();
        Object.assign(cloneInstance, this);
        return cloneInstance;
    }
}