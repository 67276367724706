<template>
    <div>
        <CreateReportBox title="What day did this occur on?">
            <b-form-datepicker v-model="report.OccurredDate" @input="$emit(`changed`)"></b-form-datepicker>
        </CreateReportBox>
        <CreateReportBox v-if="isTimeBoxVisible" title="At what time did this occur?">
            <b-form-timepicker v-model="report.OccurredTime" locale="en-GB" @input="$emit(`changed`)"></b-form-timepicker>
        </CreateReportBox>
    </div>
</template>

<script>
import Report from "../../middleware/Report"
import CreateReportBox from "../CreateReportBox.vue";

export default {
    emits: ["changed"],
    components: { CreateReportBox },
    props: {
        report: {
            type: Object,
            required: true
        }
    },
    computed: {
        isTimeBoxVisible() {
            return this.report.OccurredDate != null
        }
    }
}

</script>