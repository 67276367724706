<template>
    <div>
      <CreateReportBox :title="ReportTexts.WhatEmail">
            <input class="report-input" v-model="email" placeholder="Email of user..." @input="changed" />
        </CreateReportBox>
        <div class="email-disclosure-wrapper" v-if="fetchedGates.length == 0" :class="{ visible: emailDisclosure.length > 0 }">
            <label class="report-email-disclosure">{{ emailDisclosure }}</label>
        </div>
        <CreateReportBox :title="ReportTexts.WhatGate" v-if="fetchedGates.length > 0">
            <OptionPicker :selected="optionPickerSelected" :options="fetchedGates.map(x => x.Name)"
                @update:selected="selectGate" />
        </CreateReportBox>
    </div>
</template>

<script>
import CreateReportBox from '../CreateReportBox.vue';
import ReportGate from '../../middleware/ReportGate';
import { ReportTexts } from '../ReportTexts';
import Report from "../../middleware/Report"
import { isValidEmail } from "../../middleware/isValidEmail"
import OptionPicker from '../OptionPicker.vue';

export default {
    components: { CreateReportBox, OptionPicker },
    emits: ["loadGates", "loadGatesForId", "clearGates", "changed"],
    props: {
        report: {
            type: Object,
            required: true
        },
        fetchedGatesForEmail: {
            type: String,
            required: true
        },
        fetchedGates: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            selectedGate: -1,
            email: "",
            changeTimeout: null,
            emailDisclosure: "",
            failedFetchingGatesTimeout: null,
            ReportTexts
        }
    },
    watch: {
        fetchedGates: function (neValue) {
            this.emailDisclosure = "";
            if (neValue.length > 0) {
                clearTimeout(this.failedFetchingGatesTimeout)
            }
        },
        fetchedGatesForEmail: function (newValue) {
            if (newValue.length > 0 && this.email.length == 0) {
                this.email = newValue
                setTimeout(() => {
                    if (this.fetchedGates.length === 0)  {
                        this.emailDisclosure = "Could not find any gates for the given email"
                    }
                }, 100)
            } 
        }
    },
    computed: {
        optionPickerSelected() {
            if (this.report.Gate != null) {
                const indexOfIdInFetched = this.fetchedGates.findIndex(x => x.Id == this.report.Gate)
                return indexOfIdInFetched
            }
            return -1;
        }
    },
    methods: {
        selectGate(index) {
            this.report.Gate = this.fetchedGates[index].Id
            this.report.GateName = this.fetchedGates[index].Name
            this.$emit("changed")
        },
        changed() {
            this.emailDisclosure = ""
            if (this.fetchedGates.length > 0) {
                this.$emit("clearGates")
            }
            if (this.changeTimeout) {
                clearTimeout(this.changeTimeout)
            }
            if (this.failedFetchingGatesTimeout) {
                clearTimeout(this.failedFetchingGatesTimeout)
            }
            this.changeTimeout = setTimeout(() => {
                this.isWaitingForGates = false
                if (isValidEmail(this.email) && this.email != this.fetchedGatesForEmail) {
                    this.emailDisclosure = "Searching..."
                    this.$emit("loadGates", this.email)
                    this.failedFetchingGatesTimeout = setTimeout(() => {
                        this.emailDisclosure = "Could not find any gates for the given email"
                    }, 5000);
                }
            }, 1500);
        }
    },
    beforeMount() {
        if (this.fetchedGates.length == 0 && this.fetchedGatesForEmail.length == 0) {
            //console.log(this.report)
            if (this.report.AttemptedBy != null) {
                this.$emit("loadGatesForId", this.report.AttemptedBy)
            }
        }
        this.email = this.fetchedGatesForEmail
    }
}

</script>

<style>
.report-email-disclosure {
    font-size: 12px;
    font-style: italic;
    margin: 0;
}

.email-disclosure-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--create-report-light-gray);
    padding: 15px 20px;
    border-radius: 100em;
    margin-bottom: 40px;
    opacity: 0;
    transition: opacity 0.5s;
}

.email-disclosure-wrapper.visible {
    opacity: 1;
}
</style>