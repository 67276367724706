<template>
    <div>
        <CreateReportBox class="overview-box" v-if="!firstHidden">
            <ReportOverviewSection v-if="attemptedToDescriptionVisible" :title="ReportTexts.AttemptedTo">
                <span class="overview-text">{{ report.AttemptedToDescription }}</span>
            </ReportOverviewSection>
            <ReportOverviewSection v-if="attemptOutcomeDescriptionVisible" :title="ReportTexts.AttemptOutcome">
                <span class="overview-text">{{ report.AttemptOutcomeDescription }}</span>
            </ReportOverviewSection>
        </CreateReportBox>
        <CreateReportBox class="overview-box" v-if="occurred != null">
            <ReportOverviewSection title="When did this occurr?">
                <span class="overview-text">{{ occurred }}</span>
            </ReportOverviewSection>
        </CreateReportBox>
        <CreateReportBox class="overview-box" v-if="timesOccurredText != null || timesOccurredText != null">
            <ReportOverviewSection title="The issue has occurred" v-if="timesOccurredText != null">
                <span class="overview-text">{{ timesOccurredText }}</span>
            </ReportOverviewSection>
            <ReportOverviewSection title="Issue Categorization" v-if="categorizationText != null">
                <span class="overview-text">{{ categorizationText }}</span>
            </ReportOverviewSection>
        </CreateReportBox>
        <CreateReportBox class="overview-box" v-if="appInfoText != null">
            <ReportOverviewSection title="App Info">
                <span class="overview-text">{{ appInfoText }}</span>
            </ReportOverviewSection>
        </CreateReportBox>
        <label class="id-label" v-if="id != null">{{ "Report ID: " +  id }}</label>
    </div>
</template>

<script>
import CreateReportBox from "../CreateReportBox.vue";
import { ReportTexts } from "../ReportTexts";
import ReportOverviewSection from "../ReportOverviewSection.vue"
import { titleForIssue } from "../../middleware/IssueType"
import { titleForMobileDevicePlatform } from "../../middleware/MobileDevicePlatform"

export default {
    components: { CreateReportBox, ReportOverviewSection },
    props: {
        report: {
            type: Object,
            required: true
        },
        id: {
            type: Number,
            required: false
        }
    },
    computed: {
        appInfoText() {
            var text = ""
            if (this.report.Platform != null) {
                text += titleForMobileDevicePlatform(this.report.Platform)
                if (this.report.AppVersion?.length > 0) {
                    text += " (Version: " + this.report.AppVersion + ")"
                }
                return text;
            } else if (this.report.AppVersion?.length > 0) {
                return "Version: " + this.report.AppVersion;
            }
            return null;
        },
        attemptedToDescriptionVisible() {
            return (this.report.AttemptedToDescription?.length ?? 0) > 0;
        },
        attemptOutcomeDescriptionVisible() {
            return (this.report.AttemptOutcomeDescription?.length ?? 0) > 0;
        },
        firstHidden() {
            let isfirst = (this.report.AttemptedToDescription?.length ?? 0) == false && (this.report.AttemptOutcomeDescription?.length ?? 0) == false
            return isfirst;
        },
        occurred() {
            if (this.report.OccurredDate) {
                let d = this.report.Occurred
                if (this.report.OccurredTime) {
                    return this.formatDate(d);
                }
                return this.formatDateNoTime(d)
            }
            return null
        },
        timesOccurredText() {
            if (this.report.TimesOccurred) {
                if (this.report.TimesOccurred == 1) {
                    return this.report.TimesOccurred + " time"
                }
                return this.report.TimesOccurred + " times"
            }
            return null
        },
        categorizationText() {
            if (this.report.Type != null) {
                return titleForIssue(this.report.Type)
            }
            return null
        }
    },
    data() {
        return {
            ReportTexts
        }
    },
    methods: {
        formatDate(date) {
            const options = {
                weekday: 'long', // full name of the day of the week
                year: 'numeric', // numeric year
                month: 'long', // full name of the month
                day: 'numeric', // numeric day
                hour: '2-digit', // 2-digit hour
                minute: '2-digit', // 2-digit minute
                hour12: false // Use 24-hour time
            };

            return date.toLocaleString('en-US', options);
        },
        formatDateNoTime(date) {
            const options = {
                weekday: 'long', // full name of the day of the week
                year: 'numeric', // numeric year
                month: 'long', // full name of the month
                day: 'numeric', // numeric day
            };
            return date.toLocaleDateString('en-US', options);
        }
    }
}

</script>

<style>
.overview-box {
    justify-content: start;
    align-items: start;
    gap: 30px;
}

.report-overview-title {
    font-size: 18px;
    font-weight: 600;
}

.overview-text {
    font-size: 15px;
    font-style: italic;
    opacity: 0.8s;
    margin: 0;
}
</style>