const MobileDevicePlatform = {
    Android: 20,
    Ios: 10,
};

const allMobileDevicePlatforms = [
    MobileDevicePlatform.Android,
    MobileDevicePlatform.Ios
]

function titleForMobileDevicePlatform(platform) {
    switch (platform) {
        case MobileDevicePlatform.Android:
            return "Android"
        case MobileDevicePlatform.Ios:
            return "iOS"
    }
}

export {MobileDevicePlatform, allMobileDevicePlatforms, titleForMobileDevicePlatform}