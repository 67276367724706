<template>
    <div class="create-report">
        <div class="create-report-close-wrapper">
            <button class="create-report-close" @click="$emit(`close`)">
                <Xmark/>
            </button>
        </div>
        <CreateReportHeader :stage="stage" @update:stage="updateStage" />
        <div class="create-report-content">
            <component :is="component" :report="report" :fetchedGates="fetchedGates"
                :fetchedGatesForEmail="fetchedGatesForEmail" @loadGates="loadGates" @loadGatesForId="loadGatesForId" :id="reportId"
                @clearGates="fetchedGates = []; fetchedGatesForEmail = ``" @changed="checkForChanges()"></component>
        </div> 
        <CreateReportNavigator :is-saved="reportId != null" :has-changed="hasChanges" :stage="stage" @update:stage="updateStage" @save="$emit(`save`)" @deleteReport="$emit(`deleteReport`)" />
    </div>
</template>

<script>
import { CreateReportStage } from "../middleware/CreateReportStage"
import ReportDescription from "./description/ReportDescription.vue";
import CreateReportHeader from "./CreateReportHeader.vue";
import CreateReportNavigator from "./CreateReportNavigator.vue";
import ReportApp from "./app/ReportApp.vue";
import ReportDetails from "./details/ReportDetails.vue";
import ReportGate from "./gate/ReportGate.vue";
import ReportTime from "./time/ReportTime.vue";
import ReportOverview from "./overview/ReportOverview.vue";
import Xmark from "./xmark"

export default {
    emits: ["close", "save", "deleteReport"],
    components: {
        CreateReportHeader,
        CreateReportNavigator,
        ReportDescription,
        ReportApp,
        ReportDetails,
        ReportGate,
        ReportTime,
        ReportOverview, 
        Xmark
    },
    props: {
        source: {
            type: Object,
            required: true
        },
        reportId: {
            type: Number,
            required: false
        },
        report: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            stage: this.report.Id != null ? CreateReportStage.Overview : CreateReportStage.Description,
            component: null,
            fetchedGates: [],
            fetchedGatesForEmail: "",
            hasChanges: false,
            originalReport: this.report.duplicate()
        }
    },
    watch: {
        report: {
            handler(newValue) {
                 this.originalReport = newValue.duplicate()
            }
        }
    },
    beforeMount() {
        this.component = this.componentForStage(this.stage)
        this.hasChanges = false
        this.fetchedGates = []
        this.fetchedGatesForEmail = ""
    },
    methods: {
        checkForChanges() {
            this.hasChanges = this.report.hasChanges(this.originalReport)
        },
        updateStage(newStage) {
            if (newStage == this.stage) return;
            this.stage = newStage;
            this.component = this.componentForStage(this.stage)
        },
        componentForStage(stage) {
            switch (stage) {
                case CreateReportStage.Description:
                    return ReportDescription;
                case CreateReportStage.Time:
                    return ReportTime;
                case CreateReportStage.Details:
                    return ReportDetails;
                case CreateReportStage.App:
                    return ReportApp
                case CreateReportStage.Gate:
                    return ReportGate
                case CreateReportStage.Overview:
                    return ReportOverview
            }
        },
        async loadGates(email) {
            var id = await this.source.getUser(email)
            this.report.AttemptedBy = id;
            this.fetchedGatesForEmail = email
                     if (!id) {
                this.fetchedGates = []
                return;
            }
            this.fetchedGates = await this.source.getGates(id)
        },
        async loadGatesForId(id) {
            this.fetchedGatesForEmail = await this.source.getEmailForId(id);
            this.fetchedGates = await this.source.getGates(id);
        }
    }
}
</script>

<style>
.create-report {
    --create-report-light-gray: #e2e5e6;
    --max-width: 500px;
    --create-report-background-components: 249, 249, 249;
    --create-report-background: rgb(var(--create-report-background-components));
    width: 640px;
    max-width: 90vw;
    border-radius: 14px;
    background-color: var(--create-report-background);
    max-height: 750px;
    padding-top: 35px;
    --dark-blue: #0E323E;
    overflow-y: scroll;
    position: relative;
}

.create-report-close-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    margin-top: 20px;
    width: 100%;
    padding-right: 20px;
    pointer-events: none;
}

.create-report-close {
    --diameter: 23px;
    width: var(--diameter);
    height: var(--diameter);
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    pointer-events: all;
}

.create-report-content,
.create-report,
.create-report-content>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
}

.create-report-content {
    padding-bottom: 33px;
    width: 100%;
}

.create-report-content>div {
    min-height: 435px;
    width: 100%;
    max-width: var(--max-width);
}

.create-report::-webkit-scrollbar {
    display: none;
}

.create-report {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.report-input,
.report-text-area {
    resize: none;
    border: solid 1px lightgray;
    border-radius: 10px;
    text-indent: 12px;
    font-size: 12px;
}

.report-input {
    width: 250px;
    margin: 8px 0;
    padding: 12px 0px;
    max-width: 90%;
}

.report-text-area {
    width: 100%;
    height: 80px;
    padding: 10px 0;
}

.report-text-area:focus,
.report-input:focus {
    outline: none;
}
</style>
