<template>
    <div class="create-report-navigator-bar">
         <div class="create-report-navigator">
                <button class="report-nav-button save" v-if="hasChanged" @click="$emit(`save`)">{{saveUpdateText}}</button>
                <button class="report-nav-button delete" v-if="isSaved" @click="$emit(`deleteReport`)">Delete</button>
            </div>
        <div class="create-report-navigator">
            <button class="report-nav-button back" v-if="!isFirstStage" @click="previousStage">Previous</button>
            <button class="report-nav-button" v-if="!isLastStage" @click="nextStage">Next</button>
        </div>
    </div>
</template>

<script>
import { CreateReportStage } from '../middleware/CreateReportStage';

export default {
    emits: ['update:stage', "save", "deleteReport"],
    props: {
        stage: {
            type: Number, // Assuming CreateReportStage is numeric
            required: true
        },
        isSaved: {
            type: Boolean,
            required: true
        },
        hasChanged: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        isFirstStage() {
            return this.stage === CreateReportStage.Description;
        },
        isLastStage() {
            return this.stage === CreateReportStage.Overview;
        },
        saveUpdateText() {
            return this.isSaved ? "Save Changes" : "Create"
        }
    },
    methods: {
        nextStage() {
            if (!this.isLastStage) {
                let nextStage = this.stage + 1;
                this.$emit('update:stage', nextStage);
            }
        },
        previousStage() {
            if (!this.isFirstStage) {
                let previousStage = this.stage - 1;
                this.$emit('update:stage', previousStage);
            }
        }
    }
}
</script>


<style>
.create-report-navigator-bar {
    position: sticky;
    bottom: 0;
    max-width: var(--max-width);
    width: 100%;
    padding: 19px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(var(--create-report-background-components), 1);
}

.create-report-navigator {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.report-nav-button.back,
.report-nav-button.back:focus:not(:focus-visible) {
    color: var(--dark-blue);
    background-color: transparent;
    outline: solid 1px var(--dark-blue);
}

.report-nav-button,
.report-nav-button:focus:not(:focus-visible) {
    padding: 9px 15px;
    min-width: 70px;
    font-size: 13px;
    border: none;
    background-color: var(--dark-blue);
    border-radius: 5px;
    color: white;
    letter-spacing: 0.04em;
    text-transform: uppercase;
}

.report-nav-button.save,
.report-nav-button.save:focus:not(:focus-visible) {
    background-color: #2D9900;
}

.report-nav-button.delete,
.report-nav-button.delete:focus:not(:focus-visible) {
    background-color: #CC0600;
}

</style>