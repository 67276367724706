<template>
    <div class="report-overview-section">
        <label class="overview-title" v-if="title.length">{{ title }}</label>
        <slot></slot>
    </div>
</template>

<script>

export default {
    props: {
        title: {
            type: String,
            required: true
        }
    }
}

</script>

<style>
.report-overview-section {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.overview-title {
    font-size: 18px;
    margin: 0;
}
</style>