<template>
    <div class="create-report-box">
        <label class="create-report-box-title" v-if="showTitle">{{ title }}</label>
        <slot></slot>
    </div>
</template>

<script>

export default {
    props: {
        title: {
            type: String,
            required: false
        }
    },
    computed: {
        showTitle() {
            return (this.title != null && this.title.length > 0)
        },
    }
}

</script>

<style>

.create-report-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 14px;
    background-color: white;
    border: solid 1px rgb(241, 241, 241);
    box-shadow: 0px 5px 53px -20px rgba(0,0,0,0.1);
    padding: 28px 23px;
    width: calc(100%);
    row-gap: 15px;
}

.create-report-box-title {
    margin: 0;
}
</style>