<template>
    <dialog ref="dialog" :class="{ visible: isDialogVisible, loaded: isLoaded }" @click="handleOutsideClick">
        <div ref="slotContainer">
            <slot v-if="isLoaded" />
        </div>
    </dialog>
</template>

<script>
export default {
    name: "Popup",
    props: {
        isVisible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isLoaded: false,
            isDialogVisible: false
        };
    },
    computed: {
        dialog() {
            return this.$refs.dialog;
        }
    },
    methods: {
          handleOutsideClick(event) {
            // This ensures we have a reference to the slot container
            const slotContainer = this.$refs.slotContainer;
            if (slotContainer) {
                // Get the bounds of the slot container
                const bounds = slotContainer.getBoundingClientRect();

                // Check if the click was outside the bounds of the slot container
                if (
                    event.clientX < bounds.left ||
                    event.clientX > bounds.right ||
                    event.clientY < bounds.top ||
                    event.clientY > bounds.bottom
                ) {
                    // Click was outside the slot container, so emit an event to close the dialog
                    this.$emit('update:isOpen', false);
                }
            }
        }
    },
    watch: {
        isVisible: {
            immediate: true,
            handler(isVisible) {
                if (isVisible) {
                    this.isLoaded = true;
                    this.$nextTick(() => {
                        if (this.dialog) this.dialog.showModal();
                        setTimeout(() => {
                            this.isDialogVisible = true;
                        }, 4);
                    });
                } else {
                    this.isDialogVisible = false;
                    setTimeout(() => {
                        if (this.dialog) this.dialog.close();
                        this.isLoaded = false;
                    }, 400);
                }
            }
        }
    },
    mounted() {
        if (this.isVisible) {
            this.isLoaded = true;
            if (this.dialog) this.dialog.showModal();
        }
    }
};
</script>

<style>
dialog {
    display: none;
    opacity: 0;
    padding: 0;
    border: none;
    transition: opacity 0.3s;
    margin: 0;
    justify-content: center;
    align-items: center;
    width: 100vw;
    max-width: 100vw;
    min-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    min-height: 100vh;
    left: 0;
    background-color: transparent;
}

dialog.loaded {
    display: flex;
}

dialog.visible {
    opacity: 1;
}

dialog::backdrop {
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.4s;
}

dialog.visible::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
}
</style>
