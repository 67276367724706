<template>
    <div>
        <CreateReportBox :title="ReportTexts.Platform">
            <OptionPicker :selected="optionPickerSelected"
                :options="platforms" @update:selected="setPlatfrom($event); $emit(`changed`)" @deselect="report.Type = undefined" />
        </CreateReportBox>
        <CreateReportBox :title="ReportTexts.AppVersion">
            <input class="report-input" v-model="report.AppVersion" placeholder="(1.0.0, 1.2.4, etc...)" @keydown="$emit(`changed`)" />
        </CreateReportBox>
    </div>
</template>

<script>
import CreateReportBox from '../CreateReportBox.vue';
import OptionPicker from '../OptionPicker.vue';
import { ReportTexts } from '../ReportTexts';
import { MobileDevicePlatform, allMobileDevicePlatforms, titleForMobileDevicePlatform } from "../../middleware/MobileDevicePlatform"

export default {
    emits: ["changed"],
    components: { CreateReportBox, OptionPicker },
    props: {
        report: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            allMobileDevicePlatforms,
            android: MobileDevicePlatform.Android,
            ReportTexts,
            platforms: allMobileDevicePlatforms.map(x => titleForMobileDevicePlatform(x))
        }
    },
    computed: {
        optionPickerSelected() {
            return this.report.Platform != null ? allMobileDevicePlatforms.indexOf(this.report.Platform) : -1
        }
    },
    methods: {
        setPlatfrom(platform) {
            this.report.Platform = this.allMobileDevicePlatforms[platform]
        }
    }
}

</script>
