<template>
    <div>
        <CreateReportBox :title="ReportTexts.TimesOccurred">
            <NumberPicker class="occurrances" :value="numberPickerValue"
                :suffix="numberPickerSuffix" :min="1"
                @update:value="updateOccurrances($event)" />
        </CreateReportBox>
        <CreateReportBox :title="ReportTexts.Type">
            <OptionPicker :selected="optionPickerSelected" :options="issueOptions"
                @update:selected="updateType($event) " @deselect="report.Type = undefined" />
        </CreateReportBox>
    </div>
</template>

<script>
import CreateReportBox from '../CreateReportBox.vue';
import Report from "../../middleware/Report"
import { titleForIssue, allIssueTypes } from "../../middleware/IssueType"
import OptionPicker from '../OptionPicker.vue';
import { ReportTexts } from "../ReportTexts"
import NumberPicker from '../NumberPicker.vue';

export default {
     emits: ["changed"],
    components: { CreateReportBox, OptionPicker, NumberPicker },
    props: {
        report: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            issueOptions: allIssueTypes.map(x => titleForIssue(x)),
            ReportTexts
        }
    },
    methods: {
        updateType(index) {
            this.report.Type = index + 1
            this.$emit("changed")
        },
        updateOccurrances(index) {
            this.report.TimesOccurred = index
            this.$emit("changed")
        }
    },
    computed: {
        numberPickerValue() {
            return this.report.TimesOccurred ?? 1
        },
        numberPickerSuffix() {
            return this.report.TimesOccurred > 1 ? `times` : `time`
        },
        optionPickerSelected() {
            return (this.report.Type ?? 0) - 1
        }
    }
}

</script>

<style>
.occurrances {
    width: 200px;
    padding: 10px 0;
}
</style>