const IssueType = {
    GateUsers: 1,
    InformationFromGate: 2,
    ControllingGate: 3,
    Billing: 4
};

const allIssueTypes = [
    IssueType.GateUsers,
    IssueType.InformationFromGate,
    IssueType.ControllingGate,
    IssueType.Billing
]

function titleForIssue(issue) {
    switch (issue) {
        case IssueType.UserManagement:
            return "User management"
        case IssueType.GateUsers:
            return "Gate Users"
        case IssueType.InformationFromGate:
            return "Info from Gate"
        case IssueType.ControllingGate:
            return "Controll of gate"
        case IssueType.Billing:
            return "Billing"
    }
}

export { IssueType, titleForIssue, allIssueTypes } 
