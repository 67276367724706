<template>
    <div>
        <dmx-title :bc-items="bcItems">{{ $t('admin_dashboard_reports_title') }}</dmx-title>
        <b-row align-v="center" class="mb-1">
            <b-col>
                {{ pagination.totalListCount }} reports found
                <span class="ml-3">
                    show: <b-link @click="setPerPage(10)"
                                  :style="((options.perPage == 10) ? 'font-weight: bold;' : ' ')">10</b-link> |
                    <b-link @click="setPerPage(20)"
                            :style="((options.perPage == 20) ? 'font-weight: bold;' : ' ')">20</b-link> |
                    <b-link @click="setPerPage(100)"
                            :style="((options.perPage == 100) ? 'font-weight: bold;' : ' ')">100</b-link>
                </span>
            </b-col>
            <b-col>
                <b-input v-model="searchField" @input="searchWithText()" :loading="searchFieldIsLoading"
                         :placeholder="$t('common_search')">
                </b-input>
            </b-col>
            <b-col align="right">
                <b-button variant="success" @click="newReport()">
                    <b-icon icon="plus"></b-icon>
                    {{ $t('common_create') }}
                </b-button>
            </b-col>
        </b-row>

        <b-table id="models-table" thead-class="dmx-thead-block" :busy="loading" outlined striped bordered hover
                 :items="reportList" :fields="headers" @row-clicked="editReport">
            <template #cell(created)="{ value }">
                {{ moment.utc(value).local().format('YYYY-MM-DD HH:mm:ss') }}
            </template>
        </b-table>

        <div>
            <b-pagination align="center" v-model="options.currentPage" :total-rows="pagination.totalListCount"
                          :per-page="options.perPage" aria-controls="models-table">
            </b-pagination>
        </div>

        <Popup :is-visible.sync="isPopupOpen">
            <CreateReport :report="popup.report" :report-id="popup.id" :source="source" @save="save" @close="isPopupOpen = false" @deleteReport="deleteReport"></CreateReport>
        </Popup>
    </div>
</template>
Email is now sent

<script>
import reportService from '@/services/reportService';
import Popup from "./universal/Popup.vue"
import CreateReport from "./frontware/CreateReport.vue"
import ReportGate from "./middleware/ReportGate"
import Report from "./middleware/Report"
import { titleForIssue } from "./middleware/IssueType"
import { titleForMobileDevicePlatform } from "./middleware/MobileDevicePlatform"
import moment from 'moment'
export default {
    name: 'Reports',
    components: { Popup, CreateReport },
    data() {
        return {
            search: '',
            loading: true,
            options: {
                perPage: 10,
                currentPage: 1
            },
            //flags
            searchFieldIsLoading: false,
            // values
            searchField: '',
            reportList: [],
            // pagination
            pagination: {
                totalListCount: 0
            },
            filterTableHeadName: '',
            lastSaved: null,
            timeoutId: 0,
            moment,
            bcItems: [
                {
                    text: 'reports',
                    href: '/reports',
                    active: true,
                }
            ],
            isPopupOpen: false,
            popup: {
                report: new Report(),
                id: null
            },
            source: {
                getUser: (email) => {
                    return new Promise(async (resolve) => {
                        try {
                            var id = await reportService.getUserByEmail(email);
                            resolve(id)
                        } catch (error) {
                            resolve(null)
                        }
                    })
                },
                getGates: (userId) => {
                    return new Promise(async (resolve) => {
                        try {
                            var gates = await reportService.getGates(userId);
                            resolve(gates.map(x => new ReportGate(x.id, x.name)))
                        } catch {
                            resolve([])
                        }
                    })
                },
                getEmailForId: (id) => {
                    return new Promise(async (resolve) => {
                        try {
                            var email = await reportService.getEmailByUser(id);
                            resolve(email);
                        } catch (error) {
                            resolve("")
                        }
                    })
                }
            }
        }
    },
    computed: {
        headers() {
            return [
                {
                    label: "Tried to",
                    align: 'start',
                    sortable: false,
                    value: 'attemptedToDescription',
                    key: 'attemptedToDescription'
                },
                {
                    label: "Resulted In",
                    align: 'start',
                    sortable: false,
                    value: 'attemptOutcomeDescription',
                    key: 'attemptOutcomeDescription'
                },
                {
                    label: "App",
                    align: 'start',
                    sortable: false,
                    value: 'app',
                    key: 'app'
                },
                {
                    label: "Gate",
                    align: 'start',
                    sortable: false,
                    value: 'gateName',
                    key: 'gateName'
                },
                {
                    label: "Categorization",
                    align: 'start',
                    sortable: false,
                    value: 'type',
                    key: 'type'
                },
                {
                    label: "Times Occurred",
                    align: 'start',
                    sortable: true,
                    value: 'timesOccurred',
                    key: 'timesOccurred'
                },
                {
                    label: "Created",
                    align: 'start',
                    sortable: true,
                    value: 'created',
                    key: 'created'
                }
            ]
        }
    },
    watch: {
        options: {
            handler() {
                this.getReports()
            },
            deep: true,
        },
    },
    methods: {
        async save() {
            let updateOrCreate = {
                Type: this.popup.report.Type,
                Gate: this.popup.report.Gate,
                AttemptedBy: this.popup.report.AttemptedBy,
                AttemptedToDescription: this.popup.report.AttemptedToDescription?.length > 0 ? this.popup.report.AttemptedToDescription : undefined,
                AttemptOutcomeDescription: this.popup.report.AttemptOutcomeDescription?.length > 0 ? this.popup.report.AttemptOutcomeDescription : undefined,
                Occurred: this.popup.report.Occurred.toISOString(),
                TimesOccurred: this.popup.report.TimesOccurred,
                Platform: this.popup.report.Platform,
                AppVersion: this.popup.report.AppVersion?.length > 0 ? this.popup.report.AppVersion : undefined
            }
            //console.log(this.popup.report.Occurred)
            if (this.popup.id == null) {
                await reportService.createErrorReport(updateOrCreate)
            } else {
                await reportService.updateErrorReport(this.popup.id, updateOrCreate)
            }
            this.lastSaved = new Date()
            this.options.currentPage = 1
            this.getReports()
            this.isPopupOpen = false;
        },
        async deleteReport() {
            await reportService.deleteErrorReport(this.popup.id)
            this.isPopupOpen = false;
            this.getReports()
        },
        newReport() {
            this.popup = {
                report: new Report(),
                id: null
            }
            this.isPopupOpen = true
        },
        async editReport(e) {
            const reportModel = await reportService.getErrorReport(e.id)
            var occurredDate = undefined
            var occurredTime = undefined
            //console.log(reportModel)
            let occurred = reportModel.occurred
            if (occurred != null) {
                occurred = new Date(occurred)
                var hours = occurred.getHours()
                var minutes = occurred.getMinutes()
                occurredDate = new Date(occurred).toISOString()
                if (!(hours == 0 && minutes == 0)) {
                    var hhmm = (hours < 10 ? "0" : "") + hours.toString() + ":" + (minutes < 10 ? "0" : "") + minutes.toString();
                    occurredTime = hhmm
                }
            }
            //console.log(occurredDate, occurredTime)
            this.popup = {
                report: new Report({
                    id: reportModel.id,
                    gate: reportModel.gateId,
                    occurredDate: occurredDate,
                    occurredTime: occurredTime,
                    type: reportModel.type,
                    attemptedBy: reportModel.attemptedById,
                    attemptedToDescription: reportModel.attemptedToDescription,
                    attemptOutcomeDescription: reportModel.attemptOutcomeDescription,
                    appVersion: reportModel.appVersion,
                    platform: reportModel.platform,
                    timesOccurred: reportModel.timesOccurred
                }),
                id: e.id
            }
            this.isPopupOpen = true
        },
        setPerPage(num) {
            this.options.currentPage = 1;
            this.options.perPage = num;
        },
        searchWithText() {
            this.searchFieldIsLoading = true;
            if (this.timeoutId > 0) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = window.setTimeout(() => {
                this.getReports();
            }, 1000);
        },
        filterByTableHeader(tableHeaderName) {
            this.filterTableHeadName = tableHeaderName;
            this.getReports();
        },
        getReports() {
            const { sortBy } = this.options
            reportService.filterReports({
                Count: this.options.perPage,
                //Models: [],
                //SortBy: this.options.sortBy[0],
                SearchTerm: this.searchField,
                //SortDesc: this.options.sortDesc[0],
                Page: this.options.currentPage
            })
                .then((data) => {
                    data.items.forEach((x) => {
                        if (x.type != null) {
                            x.type = titleForIssue(x.type)
                        }
                        var app = ""
                        if (x.platform != null) {
                            app = titleForMobileDevicePlatform(x.platform)
                        }
                        if (x.appVersion != null) {
                            if (app.length > 0) {
                                app += " (" + x.appVersion + ")"
                            } else {
                                app = x.appVersion
                            }
                        }
                        if (app.length > 0) {
                            x.app = app
                        }
                    })
                    this.reportList = data.items;
                    this.searchFieldIsLoading = false;
                    this.options.currentPage = data.page;
                    this.pagination.totalListCount = data.totalListCount;
                    this.loading = false;
                })
                .catch(function (error) {
                    alert(error);
                });
        }
    },
    mounted() {
        this.getReports();
    }
}
</script>
<style scoped>
.box {
    background-color: red;
    height: 100px;
    width: 100px;
}
</style>