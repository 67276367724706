/**
 * Represents a Report Gate.
 */
export default class ReportGate {
    /**
     * Creates an instance of ReportGate.
     * @param {number} id - The unique identifier for the report gate.
     * @param {string} name - The name of the report gate.
     */
    constructor(id, name) {
        /**
         * The unique identifier for the report gate.
         * @type {number}
         */
        this.Id = id;

        /**
         * The name of the report gate.
         * @type {string}
         */
        this.Name = name;
    }
}
